import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";

import { DigicaseLogo, DigicaseLogoIcon, Avatar } from "../../image.jsx";
import { userActions } from '../../redux/actions/user.actions'

import ChangePassword from "../module/ChangePassword/index.jsx";

class Header extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            showChangePasswordModal: false,
        };
    }

    handlesidebar = () => {
        document.body.classList.toggle('mini-sidebar');
    }

    handleOpenChangePasswordModal = () => {
        this.setState({
            showChangePasswordModal: true
        });
    }

    handleCloseChangePasswordModal = () => {
        this.setState({
            showChangePasswordModal: false
        });
    }

    logout = () => {
        this.props.logout();
    }

    render() {
        return (
            <div className="header">
                {/* Logo */}
                <div className="header-left">
                    <Link to="/dashboard" className="logo">
                        <img src={DigicaseLogo} alt="Logo" />
                    </Link>
                    <Link to="/dashboard" className="logo logo-small">
                        <img src={DigicaseLogoIcon} alt="Logo" width="30" height="30" />
                    </Link>
                </div>
                {/* /Logo */}
                <a href="#0" id="toggle_btn" onClick={this.handlesidebar}>
                    <i className="fe fe-text-align-left" />
                </a>


                {/* Mobile Menu Toggle */}
                <a href="#0" className="mobile_btn" id="mobile_btn" onClick={this.Sidemenu}>
                    <i className="fa fa-bars" />
                </a>

                {/* /Mobile Menu Toggle */}
                <ul className="nav user-menu">
                    <li className="nav-item dropdown has-arrow">
                        <Dropdown className="user-dropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <span className="user-img">
                                    <img
                                        className="rounded-circle"
                                        src={Avatar}
                                        width="31"
                                        alt="Ryan Taylor"
                                    />
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#" className="no-padding">
                                    <div className="user-header">
                                        <div className="user-text">
                                            <h6>{ this.props?.userData?.first_name + " " + this.props?.userData?.last_name }</h6>
                                            <p className="text-muted mb-0">{ this.props?.userData?.user_type }</p>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="/profile"> My Profile</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={this.handleOpenChangePasswordModal}> Change Password</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={this.logout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>

                {this.state.showChangePasswordModal && 
                    <ChangePassword 
                        showChangePasswordModal={this.state.showChangePasswordModal}
                        handleOpenChangePasswordModal= {this.handleOpenChangePasswordModal}
                        handleCloseChangePasswordModal= {this.handleCloseChangePasswordModal}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.authentication.token,
    userData: state.authentication.userData
})

const mapDispatchToProps = {
    logout: userActions.logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);