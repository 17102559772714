import { Types } from '../../config/actionTypes';
import { saveToLocalStorage, loadFromLocalStorage } from '../../helper/LocalstorageHelper'

function login(userAllData) {
    return dispatch => {

        let userData = {}

        userData.id = userAllData.id
        userData.email = userAllData.email
        userData.first_name = userAllData.first_name
        userData.last_name = userAllData.last_name
        userData.token = userAllData.auth_token
        userData.user_type = userAllData.user_type

        saveToLocalStorage(userData)

        dispatch({ 
            type : Types.LOGIN, 
            payload : { userData : userData , token : userData.token }
        });
    };
}

function edit(userAllData) {
    return dispatch => {

        let digicaseUser = loadFromLocalStorage();
        let userData = digicaseUser.userData

        userData.first_name = userAllData.first_name
        userData.last_name = userAllData.last_name

        saveToLocalStorage(userData)

        dispatch({ 
            type : Types.EDIT, 
            payload : { userData : userData , token : userData.token }
        });
    };
}

function logout() {
    return dispatch => {
        var userData = localStorage.removeItem('digicaseUser');
        dispatch({ type: Types.LOGOUT, ...userData });
    }
}

export const userActions = {
    login,
    edit,
    logout,
};