import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { Scrollbars } from "react-custom-scrollbars";

class SidebarNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: null
        }
    }

    handleShow(id) {
        this.setState({
            show: id
        })
    }

    render() {

        const { location } = this.props
        let pathname = location.pathname

        const TUTOR_ROUTES = ['/tutors']
        const STUDENT_ROUTES = ['/students']
        const STATIC_PAGES_ROUTES = ['/static-pages', '/add-static-page']
        const SUBSCRIPTION_PACKAGES_ROUTES = ['/subscription-packages']
        const CASE_ROUTES = ['/cases']

        return (
            <div className="sidebar" id="sidebar">
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu">
                        <ul>
                            <li className={pathname === '/dashboard' ? "active" : ""}>
                                <Link to="/dashboard"><i className="fa fa-home" /> <span>Dashboard</span></Link>
                            </li>

                            <li className={pathname === '/admins' ? "active" : ""}>
                                <Link to="/admins"><i className="fa fa-user" /> <span>Admins</span></Link>
                            </li>

                            <li className={TUTOR_ROUTES.includes(pathname) ? "active" : ""}>
                                <Link to="/tutors"><i className="fa fa-user-md" /> <span>Tutors</span></Link>
                            </li>

                            <li className={STUDENT_ROUTES.includes(pathname) ? "active" : ""}>
                                <Link to="/students"><i className="fa fa-users" /> <span>Users</span></Link>
                            </li>

                            <li className={(STATIC_PAGES_ROUTES.includes(pathname) || pathname.includes('/edit-static-page')) ? "active" : ""}>
                                <Link to="/static-pages"><i className="fa fa-file-text-o" /> <span>Static pages</span></Link>
                            </li>

                            <li className={pathname === '/faqs' ? "active" : ""}>
                                <Link to="/faqs"><i className="fa fa-question-circle-o" /> <span>FAQs</span></Link>
                            </li>

                            <li className={pathname === '/contact-us' ? "active" : ""}>
                                <Link to="/contact-us"><i className="fa fa-address-book-o" /> <span> Contact Us</span></Link>
                            </li>

                            <li className={(SUBSCRIPTION_PACKAGES_ROUTES.includes(pathname) || pathname.includes('/subscription-package')) ? "active" : ""}>
                                <Link to="/subscription-packages"><i className="fa fa-tags" /> <span> Subscription Packages</span></Link>
                            </li>

                            <li className={(CASE_ROUTES.includes(pathname) || pathname.includes('/case')) ? "active" : ""}>
                                <Link to="/cases"><i className="fa fa-medkit" /> <span> Case Management</span></Link>
                            </li>

                            <li className={pathname === '/support' ? "active" : ""}>
                                <Link to="/support"><i className="fa fa-envelope" /> <span> Support Management</span></Link>
                            </li>

                            <li className={pathname === '/coupon-codes' ? "active" : ""}>
                                <Link to="/coupon-codes"><i className="fa fa-tag" /> <span> Coupon Codes</span></Link>
                            </li>

                            <li className={pathname === '/currency' ? "active" : ""}>
                                <Link to="/currency"><i className="fa fa-money" /> <span> Currency</span></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SidebarNav);