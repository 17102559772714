import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { userActions } from '../../../redux/actions/user.actions'
import { PASSWORD_REGEX } from '../../../config/regex';
import Api from "../../../helper/Api.js";
const api = new Api();

export class index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'token': this.props.token,
            },
            fields: {
                id: '',
                old_password: '',
                new_password: '',
                confirm_password: '',
            }
        }

        this.validator = new SimpleReactValidator({
            className: 'form-error',
            autoForceUpdate: this,
            messages: {
                in: "New Password and Confirm Password doesn't match!"
            },
            validators: {
                password_validate: {
                    message: 'A password contains at least eight characters, including at least one number and includes both lower and uppercase letters and special characters, for example #, ?, !.',
                    rule: function (val, options) {
                        var regexp = PASSWORD_REGEX
                        if (val.match(regexp)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
        });
    }

    componentDidMount() {
        let fields = this.state.fields;
        fields.id = this.props?.userData?.id;

        this.setState({ fields });
    }

    handleTextChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        let fields = this.state.fields;
        fields[name] = value

        this.setState({ fields });
    }


    handleSubmit = (event) => {
        event.preventDefault();

        let fields = {};
        fields.id = this.state.fields.id
        fields.old_password = this.state.fields.old_password
        fields.new_password = this.state.fields.new_password

        if (this.validator.allValid()) {
            api.post("/admin_change_password/", {
                headers: this.state.headers,
                data: fields
            }).then(res => {
                if (res.status === 200) { 
                    toast.success(res.message);
                    this.handleResetFields();
                    this?.props?.handleCloseChangePasswordModal()
                } else if (res.status === 401) {
                    toast.error(res.message);
                    this.props.logout();
                } else {
                    toast.error(res.message);
                }
            })
        } else {
            this.validator.showMessages();
        }
    }

    handleResetFields = () => {
        let fields = {
            id: '',
            old_password: '',
            new_password: '',
            confirm_password: '',
        };
        fields.id = this.props?.userData?.id;

        this.setState({ fields });
        this.validator.hideMessages();
    }

    render() {

        let { fields } = this.state

        return (
            <Modal show={this?.props?.showChangePasswordModal} onHide={this?.props?.handleCloseChangePasswordModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="modal-title">Change Password</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label>Old Password <span className="text-danger">*</span> </label>
                            <input 
                                type="password" 
                                className="form-control" 
                                name="old_password"
                                id="old_password"
                                value={fields?.old_password}
                                placeholder="Old Password" 
                                onChange={this.handleTextChange}
                                onBlur={() => this.validator.showMessageFor('old_password')}
                            />
                            {this.validator.message("old_password", fields["old_password"] ,"required|password_validate" )}
                        </div>
                        <div className="form-group">
                            <label>New Password <span className="text-danger">*</span> </label>
                            <input 
                                type="password" 
                                className="form-control" 
                                name="new_password"
                                id="new_password"
                                value={fields?.new_password}
                                placeholder="New Password" 
                                onChange={this.handleTextChange}
                                onBlur={() => this.validator.showMessageFor('new_password')}
                            />
                            {this.validator.message("new_password", fields["new_password"] ,"required|password_validate" )}
                        </div>
                        <div className="form-group">
                            <label>Confirm Password <span className="text-danger">*</span> </label>
                            <input 
                                type="password" 
                                className="form-control" 
                                name="confirm_password"
                                id="confirm_password"
                                value={fields?.confirm_password}
                                placeholder="Confirm Password" 
                                onChange={this.handleTextChange}
                                onBlur={() => this.validator.showMessageFor('confirm_password')}
                            />
                            {this.validator.message("confirm_password", fields["confirm_password"] , `required|in:${this.state.fields.new_password}` )}
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Save Changes</button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.authentication.userData,
    token: state.authentication.token,
})

const mapDispatchToProps = {
    logout: userActions.logout
}

export default connect(mapStateToProps, mapDispatchToProps)(index)