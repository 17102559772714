import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './App.jsx';
import { store } from './components/redux/store/store';

import './assets/js/app.js';
// import './assets/js/script.js';

import {$,jQuery} from 'jquery';
window.$ = $;
window.jQuery = jQuery;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);