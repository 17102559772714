import React, { Component } from 'react'
import Header from '../views/common/Header.jsx'
import Sidebar from '../views/common/Sidebar.jsx'

class AdminLayout extends Component {

    render() {
        return (
            <div className="main-wrapper">
                <Header />
                <Sidebar />
                {this.props.children}
            </div>
        )
    }
}

export default AdminLayout